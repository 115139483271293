import React from "react";
import styled from "styled-components";

const Content = styled.div``;

export const service = [
  {
    name: "Рентгенологическая диагностика",
    desc: "<ul><li>дентальная компьютерная томография</li><li>фотопротокол</li></ul>",
    moreText: "Подробнее о диагностике",
    more: (
      <Content>
        <p>
          Далеко не&nbsp;все стоматологические клиники могут похвастаться
          наличием дентального компьютерного томографа. Специалисты нашей
          клиники знают, что на&nbsp;сегодняшний день компьютерная томография
          (КТ) челюстей является золотым стандартом для постановки диагноза
          и&nbsp;проведения успешного лечения стоматологических пациентов.
        </p>
        <p>
          В&nbsp;клинике &laquo;Fusion"мы&nbsp;используем новейший томограф
          &laquo;Vatech&raquo; с&nbsp;технологией Green technology, уменьшающей
          дозу излучения без потери качества изображения.
        </p>
      </Content>
    ),
    price: [
      {
        title: "Прицельная внутриротовая контактная рентгенография",
        cost: "500",
      },
      {
        title: "Ортопантомограмма",
        cost: "1300",
      },
      {
        title: "Компьютерная томография верхней и нижней челюсти",
        cost: "3200",
      },
    ],
    img: "/i/service/service-1.jpg",
    imgbig: "/i/service/service-1-big.jpg",
    webp: "/i/service/service-1.webp",
    webpbig: "/i/service/service-1-big.webp",
  },
  {
    name: "Отбеливание зубов и профессиональная гигиена полости рта",
    desc: "<ul><li>отбеливание зубов системой Zoom 4 white speed</li><li>ультразвуковая чистка зубов</li><li>чистка AIRFLOW</li></ul>",
    moreText: "Подробнее о гигиене",
    more: (
      <Content>
        <p>
          Мы&nbsp;напоминаем о&nbsp;наскучившем правиле посещать стоматолога 1
          раз в&nbsp;6&nbsp;месяцев&hellip; А&nbsp;ведь делается это именно для
          того, чтобы получить качественную профессиональную чистку
          с&nbsp;использованием специального оборудования и&nbsp;услышать ценные
          советы по&nbsp;уходу за&nbsp;зубами и&nbsp;дёснами в&nbsp;повседневной
          жизни.
        </p>
        <p>
          Наша цель развить культуру стоматологической гигиены и&nbsp;донести
          до&nbsp;вас, что планово посещать стоматолога нужно так&nbsp;же
          регулярно, как и&nbsp;мастера маникюра или парикмахера, но&nbsp;реже)
          В&nbsp;таком случае объём внеплановых манипуляций
          в&nbsp;стоматологической клинике будет сведён к&nbsp;минимуму;)
        </p>
      </Content>
    ),
    price: [
      {
        title:
          "Местное применение реминерализующих препаратов в области 1 зуба",
        cost: "300",
      },
      {
        title: "Глубокое фторирование эмали зубов",
        cost: "2500",
      },
      {
        title:
          "Ультразвуковое удаление наддесневых и поддесневых зубных отложений в области 1 зуба",
        cost: "300",
      },
      {
        title:
          "Профессиональная гигиена полости рта и зубов ( ультразвук + Air Flow)",
        cost: "7500",
      },
      {
        title:
          "Индивидуальное обучение гигиене полости рта и зубов ,подбор средств и предметов гигиены полости рта",
        cost: "1000",
      },
      {
        title:
          "Профессиональное отбеливание зубов клиническое часовое за один визит (2 челюсти в линии улыбки) аппаратом ZOOM-4",
        cost: "40000",
      },
      {
        title:
          "Комплексная гигиена по стандартам GBT на аппарате AIRFLOW® Prophylaxis Master (Швейцария)",
        cost: "8500",
      },
      {
        title:
          "Профессиональное отбеливание зубов клиническое Amazing за один визит",
        cost: "18500",
      },
      {
        title:
          "Профессиональное отбеливание зубов каповое домашнее 2 челюсти (включает стоимость изготовления капп и стандартный набор отбеливающего геля)",
        cost: "16990",
      },
    ],
    img: "/i/service/service-2.jpg",
    imgbig: "/i/service/service-2-big.jpg",
    webp: "/i/service/service-2.webp",
    webpbig: "/i/service/service-2-big.webp",
  },
  {
    name: "Хирургия / Имплантация",
    desc: "<ul><li>удаление зубов любой сложности</li><li>проведение зубосохраняющих операций</li><li>дентальная имплантация</li><li>имплантация «All on 4»,«All on 6»</li><li>проведение костнопластических операций</li><li>устранение рецессий десны</li></ul>",
    more: (
      <Content>
        <p>
          В&nbsp;стоматологической практике <nobr>врач-стоматолог</nobr> хирург
          является крайней инстанцией, к&nbsp;нему обращаются тогда, когда
          сохранение зуба по&nbsp;объективным причинам становится невозможным.
          Однако удаление несостоятельного зуба является лишь началом
          на&nbsp;пути к&nbsp;реабилитации жевательного аппарата. Дентальная
          имплантация&nbsp;&mdash; это операция по&nbsp;созданию надёжной опоры
          для искусственных коронок, возвращающих способность эффективно
          откусывать и&nbsp;пережёвывать пищу, а&nbsp;также широко
          и&nbsp;ослепительно улыбаться.
        </p>
        <p>
          В&nbsp;случае, когда планируется эстетичная и&nbsp;качественная
          конструкция, удаление зуба необходимо провести аккуратно,
          с&nbsp;сохранением костных структур, чтобы не&nbsp;лишать пациента
          возможности установки имплантата в&nbsp;то&nbsp;же посещение (да,
          мы&nbsp;активно и&nbsp;успешно практикуем имплантацию в&nbsp;день
          посещения в&nbsp;лунку удалённого зуба).
        </p>
        <p>
          Иногда требуется предварительная точная и&nbsp;аккуратная работа
          по&nbsp;&laquo;наращиванию&raquo; объёма костной ткани и&nbsp;десны,
          наши специалисты в&nbsp;полной мере обладают необходимыми навыками,
          чтобы восстановление после операций проходило быстро, а&nbsp;результат
          был предсказуемым.
        </p>
      </Content>
    ),
    price: [
      {
        title: "Удаление зуба",
        cost: "от 4000",
      },
      {
        title: "Удаление ретенированного зуба",
        cost: "от 8600",
      },
      {
        title: "Резекция верхушки корня с ретроградной пломбировкой канала",
        cost: " от 22000",
      },
      {
        title: "Установка имплантата",
        cost: " от 7500",
      },
      {
        title: "Имплантация по системе  «Аll on 4»",
        cost: " от 104300",
      },
      {
        title: "Синус-лифтинг с учётом материалов закрытый",
        cost: " от 24000",
      },
      {
        title: "Синус-лифтинг с учётом материалов открытый",
        cost: " от 59500",
      },
      {
        title: "Аугментация альвеолярного гребня с учётом материалов",
        cost: " от 50000",
      },
      {
        title:
          "Костная пластика челюстно-лицевой области аутогенными ламинатами по методики Кюри",
        cost: "100000",
      },
    ],
    img: "/i/service/service-6.jpg",
    imgbig: "/i/service/service-6-big.jpg",
    webp: "/i/service/service-6.webp",
    webpbig: "/i/service/service-6-big.webp",
  },
  {
    name: "Ортопедия / Виниры",
    desc: "<ul><li>протезирование зубов вкладками</li><li>протезирование зубов винирами</li><li>протезирование зубов коронками</li><li>протезирование на имплантатах</li><li>изготовление съемных протезов</li></ul>",
    more: (
      <Content>
        <p>
          Быть <nobr>врачом-стоматологом</nobr> ортопедом&nbsp;&mdash; очень
          приятное, но&nbsp;и&nbsp;ответственное занятие, ведь именно
          он&nbsp;своей работой завершает терапевтическое, хирургическое или
          даже ортодонтическое предварительное лечение и&nbsp;презентует
          результат пациенту. Нужно понимать, что план ортопеда
          в&nbsp;техническом аспекте воплощает в&nbsp;жизнь зуботехническая
          лаборатория, именно поэтому наша клиника сотрудничает только
          с&nbsp;лучшими зубными техниками.
        </p>
        <p>
          Работа ортопеда&nbsp;&mdash; это не&nbsp;только фиксация коронки или
          винира, но&nbsp;и&nbsp;анализ состояния{" "}
          <nobr>височно-нижнечелюстного</nobr> сустава, жевательных мышц
          и&nbsp;соотношения челюстей, ведь зуб является лишь маленькой частью
          жевательной системы.
        </p>
      </Content>
    ),
    price: [
      {
        title:
          "Восстановление зуба безметалловой коронкой из диоксида циркония",
        cost: "от 33400",
      },
      {
        title: "Восстановление зуба ультратонким виниром Emax по акции",
        cost: "от 23900",
      },
      {
        title: "Коронка металлокерамичечкая с опорой на имплантате",
        cost: "от 25200",
      },
      {
        title: "Коронка из диоксида циркония с опорой на имплантате",
        cost: "от 30400",
      },
    ],
    img: "/i/service/service-5.jpg",
    imgbig: "/i/service/service-5-big.jpg",
    webp: "/i/service/service-5.webp",
    webpbig: "/i/service/service-5-big.webp",
  },
  {
    name: "Ортодонтия / Брекеты / Элайнеры",
    desc: "<ul><li>исправление положения зубов и прикуса съемной аппаратурой </li><li>исправление положения зубов и прикуса брекет-системой</li></ul>",
    more: (
      <Content>
        <p>
          Так&nbsp;же как строительство любого дома, при наличии проекта,
          начинают с&nbsp;закладки фундамента, так и&nbsp;грандиозные планы
          лечения в&nbsp;стоматологии у&nbsp;пациентов с&nbsp;аномалиями прикуса
          и&nbsp;положения зубов нужно начинать с&nbsp;проведения
          ортодонтического лечения.
        </p>
        <p>
          Хирурги, терапевты и&nbsp;ортопеды отталкиваются от&nbsp;плана лечения
          ортодонта, если у&nbsp;пациента имеются проблемы с&nbsp;прикусом.
        </p>
        <p>
          Ортодонтическое лечение&nbsp;&mdash; это не&nbsp;брекеты, будь они
          хоть с&nbsp;разноцветными резиновыми кольцами или модными
          &laquo;Incognito&raquo;, спрятавшимися от&nbsp;глаз окружающих; это
          знания и&nbsp;опыт ортодонта, а&nbsp;также правильно проведеннная
          диагностика. При соблюдении всех правил ваше лечение у&nbsp;ортодонта
          не&nbsp;будет растянуто во&nbsp;времени, а&nbsp;результат превзойдёт
          ожидания!
        </p>
      </Content>
    ),
    price: [
      {
        title: "Брекет-система с установкой. Акция!",
        cost: "27900",
      },
      {
        title: "Элайнеры",
        cost: "от 40000",
      },
    ],
    img: "/i/service/service-4.jpg",
    imgbig: "/i/service/service-4-big.jpg",
    webp: "/i/service/service-4.webp",
    webpbig: "/i/service/service-4-big.webp",
  },
  {
    name: "Лечение зубов с использованием микроскопа",
    desc: "<ul><li>лечение пульпита,периодонтита</li><li>перелечивание каналов зуба</li><li>реставрация зубов</li></ul>",
    more: (
      <Content>
        <p>
          &mdash;&nbsp;Бабушка, бабушка, а&nbsp;почему у&nbsp;тебя такие большие
          глаза?
          <br />
          &mdash;&nbsp;Это, внучка, чтобы получше тебя видеть.&copy;
        </p>
        <p>
          Так и&nbsp;в&nbsp;нашей профессии: чтобы <nobr>врач-стоматолог</nobr>{" "}
          терапевт при лечении ваших зубов чувствовал себя уверенно
          и&nbsp;комфортно, он&nbsp;должен видеть! Представьте, насколько сложно
          разглядеть невооруженным глазом все тонкости при работе
          с&nbsp;каналами зуба! Для этого в&nbsp;нашей клинике установлен
          дентальный микроскоп &laquo;CJ&nbsp;optics&raquo; c&nbsp;камерой
          &laquo;Sony&raquo;, записывающей фото и&nbsp;видео всего процесса.
        </p>
        <p>
          К&nbsp;сожалению, мы&nbsp;часто имеем дело с&nbsp;последствиями
          удаления зубов, которых, при наличии микроскопа и&nbsp;необходимого
          инструментария, можно было&nbsp;бы спасти, а&nbsp;ведь ничто
          не&nbsp;заменит свой зуб со&nbsp;100% эффективностью. Наши специалисты
          работают для вас, чтобы каждому зубу выпал шанс быть спасённым.
        </p>
      </Content>
    ),
    price: [
      {
        title: "Лечение кариеса",
        cost: "от 4670",
      },
      {
        title: "Лечение пульпита",
        cost: "от 18100",
      },
      {
        title: "Лечение периодонтита",
        cost: "от 21250",
      },
    ],
    img: "/i/service/service-8.jpg",
    imgbig: "/i/service/service-8-big.jpg",
    webp: "/i/service/service-8.webp",
    webpbig: "/i/service/service-8-big.webp",
  },
  {
    name: "Пародонтология / Лечение десен",
    desc: "<ul><li>пластика рецессии десны</li><li>лечение пародонтита</li></ul>",
    more: (
      <Content>
        <p>
          Десна входит в&nbsp;состав пародонта. Пародонт- это комплекс тканей,
          окружающих зуб и&nbsp;удерживающих его в&nbsp;альвеоле(кости).
        </p>
        <p>
          Зачастую зубы, на&nbsp;вид кажущиеся здоровыми и&nbsp;не&nbsp;имеющие
          кариеса, приходится удалять по&nbsp;причине сильной подвижности
          и&nbsp;потери поддерживающей кости. Подобные проблемы всегда
          проявляются на&nbsp;уровне дёсен в&nbsp;виде гиперемии,
          кровоточивости, дискомфорта и&nbsp;рецессий (убыли десневого края).
        </p>
        <p>
          Отсюда вывод: следите за&nbsp;внешним видом своей десны и при любых
          отклонениях от&nbsp;нормы (десна должна быть плотная, увлажнённая
          и&nbsp;розовая) обращайтесь к&nbsp;врачу.
        </p>
      </Content>
    ),
    price: [
      {
        title: "Пародонтология",
        header: true,
      },
      {
        title: "Введение лекарственных препаратов в пародонтальный карман",
        cost: "500",
      },
      {
        title:
          "Временное шинирование при заболевании пародонта в области 1 зуба",
        cost: "2300",
      },
      {
        title: "Открытый кюретаж при заболевании пародонта в области 1 зуба",
        cost: "2200",
      },
      {
        title: "Закрытый кюретаж при заболевании пародонта в области 1 зуба",
        cost: "1100",
      },
      {
        title: "Лоскутная операция в полости рта",
        cost: "6000",
      },
      {
        title:
          "Лоскутная операция в полости рта с использованием препарата Emdogain в области 1 зуба",
        cost: "22000",
      },
      {
        title:
          "Лоскутная операция в полости рта с использованием препаратов Bio Oss, Bio Gide в области 1 зуба",
        cost: "24000",
      },
      {
        title: "Гингивэктомия области 1 зуба",
        cost: "2300",
      },
      {
        title:
          "Гингивопластика (устранение рецессии десневым трасплантатом в области 1 зуба)",
        cost: "12000",
      },
      {
        title:
          "Гингивопластика (пластика преддверия полости рта десневым трансплантатом )",
        cost: "16500",
      },
      {
        title: "Гингивопластика (устранение рецессии тонельным методом)",
        cost: "35000",
      },
      {
        title: "Пластика уздечки губы",
        cost: "7100",
      },
      {
        title: "Пластика уздечки языка",
        cost: "8000",
      },
      {
        title: "Вестибулопластика с использованием трансплантата с неба",
        cost: "8000",
      },
      {
        title:
          "Ультразвуковая обработка пародонтального кармана в области 1 зуба",
        cost: "1000",
      },
      {
        title:
          "Ультразвуковое удаление наддесневых и поддесневых зубных отложений в области 1 зуба",
        cost: "1500",
      },
    ],
    img: "/i/service/service-9.jpg",
    imgbig: "/i/service/service-9-big.jpg",
    webp: "/i/service/service-9.webp",
    webpbig: "/i/service/service-9-big.webp",
  },
];
